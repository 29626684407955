.wrapper {
  width: 100%;
  height: 100%;
  /* background: -webkit-linear-gradient(to right, #70e1f5, #ffd194);
  background: linear-gradient(to right, #70e1f5, #ffd194); */

  animation: 10s linear infinite;
  animation-direction: alternate;
}
@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.background {
  position: fixed;
}
/* scroll-bar */
