.mac-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: url("/src/assets/mac/Wallpaper.png") no-repeat;
  background-size: cover;
}

.navbar-container {
  background: rgba(0, 0, 0, 0.18);
  background-blend-mode: darken;
  backdrop-filter: blur(67.95704650878906px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.5em;
}

.navbar-flexbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0em 1em;
}

.macos-text {
  color: #fff;
  text-align: right;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3), 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  font-family: SF Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

.right-side {
  display: flex;
  align-items: center;
}

.left-side {
  display: flex;
  align-items: center;
}

.dock {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 2.5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px;
  background: rgba(246, 246, 246, 0.36);
  backdrop-filter: blur(40.774227142333984px);
  padding: 0% 1%;
  height: 65px;
}

.dock-icon {
  cursor: pointer;
}

.dock-container {
  display: flex;
  flex-direction: row;
}

.application-container {
  top: 5%;
  left: 15%;
  width: 70%;
  height: 80%;
  position: absolute;
  background-color: rgb(63, 62, 62);

  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 25px 30px 0px rgba(0, 0, 0, 0.35),
    0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.maximize-container {
  width: 100%;
  height: 90%;
}

.application-controls {
  width: 100.1%;
  height: 4%;
  border-radius: 10px 10px 0px 0px;
  background: #dadada;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4em;
  position: relative;
  top: -1px;
}

.application-controls div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 100%;
  cursor: pointer;
}

.close {
  margin-left: 1%;
  background: #ff5e57;
}

.minimize {
  background: #ffbb2e;
}

.maximize {
  background: #38c149;
}

.drag-area {
  opacity: 0.2;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  top: 2.5%;
  left: 0%;
  pointer-events: none;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  background-color: rgb(63, 62, 62);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  border-radius: 6px;
  left: 50%;
  width: max-content;
  padding: 5px 10px;
  transform: translate(-50%, -150%);
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.dock-icon-container {
  position: relative;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dock-icon-container:hover .tooltiptext {
  visibility: visible;
}

.content-container {
  overflow-y: scroll;
  position: relative;
  height: 100%;
  height: 96%;
  display: flex;
  justify-content: center;
  background-color: rgb(63, 62, 62);
  user-select: none;
  -webkit-user-drag: none;
}

.content-container::-webkit-scrollbar {
  background-color: none;
  width: 10px;
}

/* background of the scrollbar except button or resizer */
.content-container::-webkit-scrollbar-track {
  background-color: none;
}

/* scrollbar itself */
.content-container::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 10px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
.content-container::-webkit-scrollbar-button {
  display: none;
}
